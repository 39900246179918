html,
body,
#root,
.App {
  margin: 0;
  height: 100vh;
  width: 100vw;
  max-width: 2000px;
  overflow: auto;
}

@import url("https://fonts.googleapis.com/css?family=Open+Sans");
